
export default {
  datetimeFormat: "DD/MM/YYYY HH:mm",
  datetimeLongFormat: "DD/MM/YYYY HH:mm:ss",
  dateFormat: "DD/MM/YYYY",
  timeFormat: "HH:mm",
  apiDateFormat: "YYYY-MM-DD",
  apiDateTimeFormat: "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]",
  listDefaultItems: 10,
  planCycleUnlimitedValue: 'UNLIMITED',
  serviceCycleUnlimitedValue: 'UNLIMITED',
  productUnlimitedValue: 'UNLIMITED',
  fieldKeyRegex: /^[A-Za-z0-9._]*$/,
  keyUppercaseRegex: /^[A-Z0-9_]+$/,
  keyUppercaseCleanRegex: /[^A-Z|0-9_]/g,
  keyRegex: /^[A-Za-z0-9_]*$/,
  keyCleanRegex: /[^a-z|A-Z|0-9_]/g,
  defaultLogo: 'billRun-cloud-logo.png',
  billrunCloudLogo: 'billRun-cloud-logo.png',
  billrunLogo: 'billRun-logo.png',
  queue_calculators: ['customer', 'rate', 'pricing', 'tax'],
  logoMaxSize: 2,
  importMaxSize: 8,
  chargingBufferDays: 5,
}
